.navbar-logo {
  width: 20;
}

.custom-dropdown .dropdown-menu {
  background-color: #fff;
  color: #fff;
  opacity: 0;
  border: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s; /* Add transition properties */
}

.custom-dropdown.show .dropdown-menu {
  background-color: #fff;
  opacity: 1;
  margin: -5px;
  text-shadow: 0px 3px 6px rgba(255, 255, 255, 0.16);
  visibility: visible;
  transition-delay: 0s; /* Remove the delay for the transition */
}

.custom-dropdown .dropdown-item {
  color: #000;
  background-color: #fff;
}
.custom-dropdown .dropdown-item:hover {
  color: #fff;
  background-color: #d53636;
}
.nav-link {
  color: #fff !important;
  padding-left: 30px !important;
  font-size: 20px !important;
  text-shadow: 0px 3px 6px rgba(255, 255, 255, 0.16) !important;
}

.navbar-toggler {
  border: none !important;
}

.highlighted {
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: bold;
  color: #d53636;
}

h1 {
  font-family: "Montserrat-Bold", sans-serif;
  font-weight: bold;
  color: "#fff";
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

h2 {
  font-family: "Montserrat-Bold", sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: "#fff";
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

span {
  font-family: "Montserrat", sans-serif;
  color: "#fff";
}

.btn-primary {
  background-color: #d53636 !important;
  border: 0px !important;
  font-size: 17px;
}

.btn-secondary {
  background-color: #d53636 !important;
  background-color: transparent;
  color: #fff;
  border: 0px !important;
}

.btn-secondary:hover {
  background-color: #fff !important;
  color: #d53636 !important;
  border: 0px !important;
}

@keyframes fadeInText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

h1 .highlighted {
  color: #d53636;
}

@media (max-width: 600px) {
  .nav-link {
    margin: 0px !important;
    padding: 0px !important;
  }
  .facebook {
    margin-right: 0px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    padding: 0px !important;
  }
  .contacts {
    font-size: 15px !important;
  }
}
