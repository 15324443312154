@font-face {
  font-family: "Montserrat";
  src: url("../Fonts/Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("../Fonts/Montserrat-Bold.ttf");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../Fonts/Montserrat-Italic.ttf");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../Fonts/Montserrat-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}
